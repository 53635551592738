import styled from 'styled-components';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { media } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
export const NoticesOverlayMenu = styled.div`
  width: 60vw;
  height: 35vh;

  @media only screen and ${media.md} {
    width: 25vw;
    height: 40vh;
  }

  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const LinkBtn = styled(BaseButton)`
  &.ant-btn {
    padding: 0;
    font-size: 0.875rem;
    height: unset;
    line-height: unset;
  }
`;
export const StyledBaseButton = styled(BaseButton)`
  border-radius: 0.625rem;
  height: 2.25rem;
  &:disabled {
    border: 1px solid ${BASE_COLORS.lightgrey};
    color: ${BASE_COLORS.lightgrey};
    background: ${BASE_COLORS.white};
  }
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.3rem;
  position: relative;
`;

export const Btn = styled(BaseButton)`
  width: 100%;
`;

export const Text = styled(BaseTypography.Text)`
  width: 100%;
  display: block;
  text-align: center;
`;

interface SpaceWrapperProps {
  isRead?: boolean;
}
export const HeadingRow = styled(BaseRow)`
  padding-left: 16px;
  padding-right: 23px;
`;

export const Heading = styled(BaseTypography)`
  font-size: 1.6 rem;
  color: #141414;
  font-weight: 500;
`;

export const SpaceWrapper = styled(BaseSpace)<SpaceWrapperProps>`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: 1;
`;
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin-bottom: 10px;
`;
