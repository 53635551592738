import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetModelItems } from '@app/api/table.api';
import { TAccountNotifications } from '@app/types/accountNotifications';
import { Loading } from '@app/components/common/Loading/Loading';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import styled from 'styled-components';
import { format, formatDistanceToNow } from 'date-fns';
import { BaseDivider } from '@app/components/common/BaseDivider/BaseDivider';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';

export default function AccountNotificationsPage() {
  const { t } = useTranslation();
  const { id: notificationId } = useParams<{ id: string }>();
  const parsedNotificationId = notificationId ? parseInt(notificationId, 10) : null;

  const { data, isLoading } = useGetModelItems<TAccountNotifications>({
    model: 'accountnotificationsextended',
    queryParams: `$filter=(notificationId eq ${parsedNotificationId})`,
    customQueryKey: `accountnotificationsextended-${parsedNotificationId}`,
  });

  const notification = data?.items.find((item) => item.notificationId === parsedNotificationId);

  const Container = styled.div`
    border: 1px solid #e9eaeb;
    border-radius: 9px;
    background-color: #ffff;
    width: 100%;
    min-height: 70vh;
    padding: 20px;
  `;

  const HeaderComponent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    margin-bottom: 16px;
  `;

  const Title = styled.span`
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 16px;
    height: max-content;
  `;

  const Date = styled.span`
    font-size: 0.865rem;
    color: #6b6b6b;
  `;

  if (isLoading || !notification) {
    return <Loading />;
  }

  const formattedTimeSent = format(notification.timeSent, 'EEEE, MMMM dd yyyy');
  const timeAgo = formatDistanceToNow(notification.timeSent, { addSuffix: true });
  const subTitle = `Sent on ${formattedTimeSent} (${timeAgo})`;

  return (
    <>
      <PageTitle>{t('common.manageNotifications')}</PageTitle>
      <Container>
        <HeaderComponent>
          <Title>{notification.title}</Title>
          <Date>{subTitle}</Date>
        </HeaderComponent>
        <BaseDivider></BaseDivider>
        <HtmlEditor
          key={notification.notificationId}
          initialValue={notification.content}
          readOnly={true}
          enableMedia={true}
        />
      </Container>
    </>
  );
}
